import { mapActions, mapGetters, mapMutations } from "vuex";
import { DEFAULT_JOB_DETAILS_HR } from "@/assets/form-builders/JobsFields";
import { DEFAULT_COMPANY_DETAILS } from "@/assets/form-builders/JobsFields";
import store from "@/global-store.index";
export const NEW_JOB_MODULE_NAME = "NewJobModule";

const initState = {
  is_connected: false,
  active_input_id: null,
  ai_generating: false,
  job_created: false,
  job_details: DEFAULT_JOB_DETAILS_HR,
  company_data: DEFAULT_COMPANY_DETAILS,
  bj_id: null,
  bj_sec_key: null,
};

const NewJobModule = {
  namespaced: true,
  state: () => initState,
  getters: {
    is_connected: (s) => s.is_connected,
    job_details: (s) => s.job_details,
    company_data: (s) => s.company_data,
    active_input_id: (s) => s.active_input_id,
    ai_generating: (s) => s.ai_generating,
    job_created: (s) => s.job_created,
  },
  mutations: {
    RESET(state) {
      /* ===== DEFAULT STATE ======*/
      state.job_details.prompt = null;
      state.job_details.job_description = null;
      state.job_details.job_title = null;
      state.job_details.job_status = "draft";
      state.job_details.job_expireIn = new Date();
      state.job_details.job_skills = [];
      state.job_details.skills_priority = [];
      state.job_details.job_preferred_education = "bachelors";
      state.job_details.job_min_experience = 0;
      state.job_details.job_type = "contract";
      state.job_details.job_workplace_type = "on_site";
      state.job_details.employer_id = null;
      state.job_details.employer_attributes = {};
      /* ========================== */
      state.job_created = false;
      state.ai_generating = false;
      state.active_input_id = null;
      state.bj_id = null;
      state.bj_sec_key = null;
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    set_ai_generating(state, flag) {
      state.ai_generating = flag;
    },
    set_job_created(state, flag) {
      state.job_created = flag;
    },
    create(state, { body: created_job, params }) {
      state.job_created = true;
      state.job_details = DEFAULT_JOB_DETAILS_HR;
      console.log(created_job);
      store.commit("JobsModule/create", { body: created_job, params });
    },
    fill_job_fields(state, { body: job_details, params }) {
      if (state.ai_generating && job_details.response_status === "InProgress") {
        state.bj_id = job_details.bj_id;
        state.bj_sec_key = job_details.bj_sec_key;
      }
      if (state.ai_generating && job_details.response_status === "Completed") {
        const job_details_keys = Object.keys(initState.job_details);
        for (const _key of job_details_keys) {
          state.job_details[_key] = job_details[_key]
            ? job_details[_key]
            : state.job_details[_key];
        }
        state.ai_generating = false;
      }
    },
    inputFocused(state, e) {
      state.active_input_id = e.target.id;
    },
    setActiveInputId(state, id) {
      state.active_input_id = id;
    },
    updateJobDetails(state, { id, value }) {
      state.job_details[id] = value;
    },
    updateJobDetailsForActiveInput(state, value) {
      state.job_details[state.active_input_id] = value;
    },
    addSkill(state, skill) {
      state.job_details.job_skills.push(skill);
    },
    removeSkill(state, idx) {
      state.job_details.job_skills.splice(idx, 1);
    },
    setJobIdentifiers(state, { bj_id, bj_sec_key }) {
      state.bj_id = bj_id;
      state.bj_sec_key = bj_sec_key;
    },
    fetch_hr_team_employers(state, { body: company_data, params }) {
      state.company_data = company_data;
    },
  },
  actions: {
    async cancelBackgroundJob({ state }) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      if (state.bj_id && state.bj_sec_key) {
        try {
          const url = `${protocol}//${host}/user/cancel_background_job`;
          console.log("Cancel Background Job URL:", url);
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              bj_id: state.bj_id,
              bj_sec_key: state.bj_sec_key,
            }),
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json().catch(() => {
            console.warn("Response is not JSON");
            return null;
          });
          console.log("Job cancelled successfully:", result);
          return result;
        } catch (error) {
          console.error("Failed to cancel job:", error);
          throw error;
        }
      } else {
        console.warn("Job ID and Security Key are not available");
      }
    },
  },
};

export const mapJobsActions = (data) => mapActions(NEW_JOB_MODULE_NAME, data);
export const mapJobsGetters = (data) => mapGetters(NEW_JOB_MODULE_NAME, data);

export const mapJobsMutations = (data) =>
  mapMutations(NEW_JOB_MODULE_NAME, data);

export default NewJobModule;
