import { mapActions, mapGetters, mapMutations } from "vuex";
import { DEFAULT_COMPANY_DETAILS } from "@/assets/form-builders/JobsFields";
export const HR_MODULE_NAME = "HRCompanyModule";

const initState = {
  is_connected: false,
  employers: null,
  selected_employer: null,
  selected_employer_id: null,
  filter_options: null,
  isLoading: false,
  company_details: DEFAULT_COMPANY_DETAILS,
  active_input_id: null,
  dashboard_data: null,
  manage_data: null,
};

const HRCompanyModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    employers: (s) => s.employers,
    filter_options: (s) => s.filter_options,
    selected_employer: (s) => s.selected_employer,
    selected_employer_id: (s) => s.selected_employer_id,
    isLoading: (s) => s.isLoading,
    dashboard_data: (s) => s.dashboard_data,
    manage_data: (s) => s.manage_data,
    company_details: (s) => s.company_details,
    active_input_id: (s) => s.active_input_id,
    employerByID: (s) => (id_to_match) => {
      if (s.employers.length === 0) return null;
      if (!id_to_match) return s.employers[0];
      return s.employers.find(
        (employer) => employer.employer_id === id_to_match
      );
    },
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    setLoading(state, flag){
      state.isLoading = flag;
    },
    employer_filter_options(state, { body: filter_options, params }) {
      state.filter_options = filter_options;
    },
    index_employer(state, { body: employers, params }) {
      // console.log("Received employers:", employers);
      state.employers = employers;
      state.isLoading = false;
    },
    index_dashboard(state, { body: dashboard_data, params }) {
      // console.log("dashboard", getters.dashboard);
      state.dashboard_data = dashboard_data;
      state.isLoading = false;
    },
    create_employer(state, { body: employer, params }) {
      console.log(employer);
      state.employers = [...state.employers, employer];
      // console.log(getters.selected_employer);
      state.selected_employer = employer || null;
      // console.log(getters.selected_employer);

      // state.employers.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
    },
    //Single Employer
    edit_company(state, { body: employer, params }) {
      state.selected_employer = employer || null;
      console.log(employer);
    },
    //Delete Employer
    destroy_company(state, { body: employer, params }) {
      const index = state.employers.findIndex(
        (b) => b.employer_id === employer.employer_id
      );
      if (index > -1) {
        state.employers.splice(index, 1);
      }

      state.selected_employer = null;
    },
    update_employer(state, { body: employer, params }) {
      const index = state.employers.findIndex((b) => b.employer_id === employer.employer_id);
      if (index > -1) {
        const updatedEmployerDetails = { ...state.employers[index], ...employer }; // update changes only
        state.employers.splice(index, 1, updatedEmployerDetails);
        
        // Update selected_employer if necessary
        if (state.selected_employer && employer.employer_id === state.selected_employer.employer_id) {
          state.selected_employer = updatedEmployerDetails;
        }
      }
    },
    inputFocused(state, e) {
      state.active_input_id = e.target.id;
    },
    setActiveInputId(state, id) {
      state.active_input_id = id;
    },
    updateCompanyDetailsForActiveInput(state, value) {
      state.company_details[state.active_input_id] = value;
    },
    // manage access
    get_current_employer_permissions(state, {body: manage_data, params}){
      state.manage_data = manage_data;
    },
    create_access_employer_to_hr_team_member(state, {body: body, params}){
      state.manage_data.assigned_team_members.push(body);
      state.manage_data.remaining_team_members = state.manage_data.remaining_team_members.filter(
        (member) => member.hr_team_member_details.hr_team_id !== body.hr_team_member_details.hr_team_id
      );
    },
    update_access_employer_to_hr_team_member(state, {body: body, params}){
      const index = state.manage_data.assigned_team_members.findIndex(
        (b) => b.hr_team_member_details.hr_team_id === body.hr_team_member_details.hr_team_id
      );
      if (index > -1) {
        state.manage_data.assigned_team_members.splice(index, 1, body);
      }
    },
    destroy_access_employer_to_hr_team_member(state, {body: access_data, params}){
      const index = state.manage_data.assigned_team_members.findIndex(
        (b) => b.hr_team_member_details.hr_team_id === access_data.hr_team_id
      );
      if (index > -1) {
        // append data to required access list 
        state.manage_data.remaining_team_members.push({hr_team_member_details: state.manage_data.assigned_team_members[index].hr_team_member_details});
        // remove from already have access list
        state.manage_data.assigned_team_members.splice(index, 1);
      }
    },
  },
  actions: {
    set_selected_employer_id({ state }, employer_id) {
      state.selected_employer_id = employer_id;
    },
    set_selected_employer({ state, getters }, employer_id) {
      console.log("Setting Employer");
      // console.log(employer_id);
      state.selected_employer = { ...getters.employerByID(employer_id) };
      console.log(getters.selected_employer);
    },
  },
};

export const mapHRActions = (data) => mapActions(HR_MODULE_NAME, data);
export const mapHRGetters = (data) => mapGetters(HR_MODULE_NAME, data);

export const mapHRMutations = (data) => mapMutations(HR_MODULE_NAME, data);

export default HRCompanyModule;
