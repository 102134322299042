import { mapActions, mapGetters, mapMutations } from "vuex";
import router from "@/global-router";
import moment from "moment";

export const SCHEDULER_MODULE_NAME = "SchedulerModule";

export const COMMON_AVAILABILITY_GROUP_ID = "common_availability";

const initState = {
  COMMON_AVAILABILITY_GROUP_ID,
  is_connected: false,
  calendarView: "dayGridMonth",
  calendar_events: null,
  candidate_events: null,
  employer_events: null,
  common_available_events: null,
  all_available_events: { employer: null, candidate: null },
  selected_event: null,
  current_job: null,
  team_members: null,
  common_available_time: {
    including_meetings: null,
    excluding_meetings: null
  },
  common_calendar_colors: {
    candidate_bg: { color: "#ffc300", text: "Candidate Availability", transparent: true },
    employer_bg: { color: "#22aaff", text: "Your Availability", transparent: true },
    common_meetings: { color: "#1cb622", text: "Common Availability", transparent: true },
    candidate_other_meetings: { color: ["#7f0000"], text: "Candidate Unavailability", transparent: true },
    employer_current_candidate_this_job_meeting: { color: "#901aff", text: "This Job & This Candidate" },
    employer_this_job_other_meetings: { color: "#c183fd", text: "This Job & Other Candidates" },
    employer_current_candidate_other_job_meeting: { color: "#ff1164", text: "Other Job & This Candidate" },
    employer_other_job_meetings: { color: "#ff5794", text: "Other Jobs" }
  }
};

const SchedulerModule = {
  namespaced: true,
  state: () => initState,
  getters: {
    is_connected: (s) => s.is_connected,
    COMMON_AVAILABILITY_GROUP_ID: (s) => s.COMMON_AVAILABILITY_GROUP_ID,
    selected_event: (s) => s.selected_event,
    calendar_events: (s) => s.calendar_events,
    candidate_events: (s) => s.candidate_events,
    employer_events: (s) => s.employer_events,
    common_calendar_colors: (s) => s.common_calendar_colors,
    calendarView: (s) => s.calendarView,
    current_job: (s) => s.current_job,
    common_available_events: (s) => s.common_available_events,
    team_members: (s) => s.team_members,
    common_available_time:
      (s) =>
      (_date = null) => {
        if (!s.common_available_time.including_meetings && !s.common_available_time.excluding_meetings) return null;
        if (!_date) return s.common_available_time;

        const common_available_time_on_date = { including_meetings: [], excluding_meetings: [] };

        common_available_time_on_date.including_meetings = s.common_available_time.including_meetings?.filter(
            (_event) => _event.start.getDate() === _date.getDate()
          );
        common_available_time_on_date.excluding_meetings = s.common_available_time.excluding_meetings?.filter(
            (_event) => _event.start.getDate() === _date.getDate()
          );

        return common_available_time_on_date;
      },
    all_available_events:
      (s) =>
      (_date = null) => {
        if (!s.all_available_events.employer || !s.all_available_events.candidate) return null;
        if (!_date) return s.all_available_events;

        const available_events_on_date = { employer: [], candidate: [] };

        available_events_on_date.employer = s.all_available_events.employer.filter((_event) => _event.start.getDate() === _date.getDate());
        available_events_on_date.candidate = s.all_available_events.candidate.filter(
            (_event) => _event.start.getDate() === _date.getDate()
          );

        return available_events_on_date;
      },
    profile_events: (s) => {
      if (!s.calendar_events) return null;

      console.log(s.current_user);

      const all_profile_events = [];
      s.calendar_events.forEach((_event) => {
        all_profile_events.push({
          ..._event,
          owner: _event.status === "meeting" ? "employer" : "candidate",
          non_repeatable: _event.status === "meeting",
          non_editable: _event.status === "meeting" ? s.current_user === "candidate" : false     
        });
      });

      return all_profile_events;
    },
    common_scheduler_events: (s) => {
      if (!s.candidate_events || !s.employer_events) return null;

      const _fake = s.calendarView;
      const _fake2 = s.common_available_events;

      const all_candidate_events = [];
      const all_employer_events = [];

      s.candidate_events.available.forEach((event) => {
        all_candidate_events.push({
          ...event,
          display: "background",
          color: s.common_calendar_colors.candidate_bg.color,
          title: "",
          owner: "candidate",
          overlap: true
        });
      });

      s.employer_events.available.forEach((event) => {
        all_employer_events.push({
          ...event,
          display: "background",
          color: s.common_calendar_colors.employer_bg.color,
          title: "",
          owner: "employer",
          overlap: true
        });
      });

      s.candidate_events.other_meeting.forEach((event) => {
        all_candidate_events.push({
          ...event,
          display: "background",
          color: s.common_calendar_colors.candidate_other_meetings.color.slice(-1)[0],
          title: "",
          owner: "candidate",
          non_editable: true
        });
      });

      s.employer_events.other_meeting.forEach((event) => {
        const isMeetingForThisJob = event.job_hashid === router.currentRoute.value.params.job_hashid;
        all_employer_events.push({
          ...event,
          display: s.calendarView === "dayGridMonth" ? "none" : "list-item",
          color: isMeetingForThisJob
            ? s.common_calendar_colors.employer_this_job_other_meetings.color
            : s.common_calendar_colors.employer_other_job_meetings.color,
          owner: "employer",
          non_editable: true
        });
      });

      s.employer_events.common_meeting.forEach((event) => {
        const isMeetingForThisJob = event.job_hashid === router.currentRoute.value.params.job_hashid;

        all_employer_events.push({
          ...event,
          display: s.calendarView === "dayGridMonth" ? (isMeetingForThisJob ? "block" : "none") : "list-item",
          color: isMeetingForThisJob
            ? s.common_calendar_colors.employer_current_candidate_this_job_meeting.color
            : s.common_calendar_colors.employer_current_candidate_other_job_meeting.color,
          owner: "employer",
          non_editable: !isMeetingForThisJob,
          non_repeatable: true,
          editable: isMeetingForThisJob,
          overlap: isMeetingForThisJob,
          constraint: isMeetingForThisJob ? COMMON_AVAILABILITY_GROUP_ID : false
        });
      });

      const response = [...all_employer_events, ...all_candidate_events];
      s.common_available_events ? response.push(...s.common_available_events) : null;

      return response;
    }
  },
  mutations: {
    RESET: (state) => {
      state.calendarView = "dayGridMonth";
      state.calendar_events = null;
      state.candidate_events = null;
      state.employer_events = null;
      state.selected_event = null;
      state.current_job = null;
      state.common_available_events = null;
      state.all_available_events = { employer: null, candidate: null };
    },

    connected(state, flag) {
      state.is_connected = flag;
    },

    index_calendar_event(state, { body: response, params }) {
      state.calendar_events = response.events;

      if (response.metadata.user === "candidate")
        state.candidate_events = {
          available: response.events.filter((e) => e.status === "available")
        };
      else
        state.employer_events = {
          available: response.events.filter((e) => e.status === "available")
        };
    },
    index_common_events(state, { body: response, params }) {
      state.candidate_events = response.candidate.events;
      state.employer_events = response.employer.events;
    },
    create_calendar_event(state, { body: response, params }) {
      let event_user = response.metadata.user;
      console.log("calendar_event", response);
      if (params.status !== "meeting")
        state.calendar_events = state.calendar_events ? [...state.calendar_events, response.events] : [response.events];

      if (event_user != "candidate" && params.status === "meeting")
        state.employer_events.common_meeting = [...state.employer_events.common_meeting, response.events];
      else if (event_user === "candidate") state.candidate_events.available = [...state.candidate_events.available, response.events];
      else if (event_user !== "candidate") state.employer_events.available = [...state.employer_events.available, response.events];

      state.selected_event = response.events;

      if (response.events.status === "meeting")
        state.selected_event = {
          ...state.selected_event,
          non_repeatable: true
        };
    },
    update_calendar_event(state, { body: response, params }) {
      if (state.calendar_events) {
        const updated_event_idx = state.calendar_events.findIndex((event) => event.id === parseInt(params.id));
        state.calendar_events.splice(updated_event_idx, 1, response.events);
        state.calendar_events = [...state.calendar_events];
      }

      for (const key in state.employer_events) {
        state.employer_events[key].forEach((_event, idx, event_arr) => {
          if (_event.id === parseInt(params.id)) {
            event_arr.splice(idx, 1, response.events);
            state.employer_events[key] = [...state.employer_events[key]];
          }
        });
      }

      state.selected_event = response.events;
    },
    destroy_calendar_event(state, { params }) {
      let deleted_event_idx = null;
      if (state.calendar_events) {
        deleted_event_idx = state.calendar_events.findIndex((event) => event.id === parseInt(params.id));
        state.calendar_events.splice(deleted_event_idx, 1);
        state.calendar_events = [...state.calendar_events];
      }

      for (const key in state.employer_events) {
        state.employer_events[key].forEach((_event, idx, event_arr) => {
          if (_event.id === parseInt(params.id)) {
            event_arr.splice(idx, 1);
            state.employer_events[key] = [...state.employer_events[key]];
          }
        });
      }

      state.selected_event = null;
    },
    fetch_team_members(state, { body: team_members, params }) {
      state.team_members = team_members;
    }
  },
  actions: {
    change_current_job({ state }, job) {
      state.current_job = job;
    },
    changeCalendarView({ state }, new_view) {
      state.calendarView = new_view;
    },
    addPropsToSelectedEvent({ state }, props) {
      state.selected_event = { ...state.selected_event, ...props };
    },
    change_selected_event({ state, getters }, event_id) {
      let from_where = "common_scheduler_events";

      if (getters.profile_events && getters.profile_events.length > 0) from_where = "profile_events";

      state.selected_event = getters[from_where].find((event) => event.id === parseInt(event_id));

      console.log(state.selected_event);
    },
    auto_schedule_meet({ commit }, { body, params }) {
      if(body.status != "unsuccess")
      {
        commit("create_calendar_event", {
          body,
          params: { ...params, user: "employer", status: "meeting" }
        });
      }
    },
    calculate_common_availability({ state }, all_events) {
      state.all_available_events.employer = all_events
        .filter(
          (e) =>
            //moment(e.startStr).isSame(meeting.event_start, "day") &&
            e.extendedProps.owner === "employer" && e.extendedProps.status !== "meeting"
        )
        .toSorted((a, b) => (moment(a.startStr).isSameOrBefore(b.startStr) ? -1 : 1));

      state.all_available_events.candidate = all_events
        .filter(
          (e) =>
            //moment(e.startStr).isSame(meeting.event_start, "day") &&
            e.extendedProps.owner === "candidate" && e.extendedProps.status !== "meeting"
        )
        .toSorted((a, b) => (moment(a.startStr).isSameOrBefore(b.startStr) ? -1 : 1));

      //STEP 1 : Figure out all the common timeframes and store them in common_times
      /*
      If Employee_Availability[n] starts on or after Candidate_Availability[n] Ends, ignore Candidate_Availability[n]
      if Employee_Availability[n] ends on or before Candidate_Availability[n] starts, ignore Candidate_Availability[n]
      Then, we get common_time by getting max start_time and min end_time of the two Availabilities
      */
      const common_times = [];
      state.common_available_events = [];

      state.all_available_events.employer.forEach((employee_event) => {
        state.all_available_events.candidate.forEach((candidate_event) => {
          if (moment(employee_event.startStr).isSameOrAfter(candidate_event.endStr)) return;
          if (moment(employee_event.endStr).isSameOrBefore(candidate_event.startStr)) return;

          const common_availability_event = {
            id: Math.round(Math.random() * 100000),
            start: moment.max(moment(employee_event.startStr), moment(candidate_event.startStr)).toDate(),
            end: moment.min(moment(employee_event.endStr), moment(candidate_event.endStr)).toDate(),
            groupId: COMMON_AVAILABILITY_GROUP_ID,
            display: "background",
            color: state.common_calendar_colors.common_meetings.color,
            overlap: true
          };

          common_times.push(common_availability_event);
          state.common_available_events.push(common_availability_event);
        });
      });

      state.common_available_time.including_meetings = common_times;

      console.log("Common Availability Events", state.common_available_events);
    }
  }
};

export const mapSchedulerActions = (data) => mapActions(SCHEDULER_MODULE_NAME, data);
export const mapSchedulerGetters = (data) => mapGetters(SCHEDULER_MODULE_NAME, data);

export const mapSchedulerMutations = (data) => mapMutations(SCHEDULER_MODULE_NAME, data);

export default SchedulerModule;
