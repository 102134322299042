const HRRoutes = [
  {
    path: "/hr_team",
    meta: { allowed_user_types: ["hr_team"] },
    component: () => import("@/hr-company/HRCompany.vue"),
    children: [
      {
        path: "dashboard",
        name: "hr-company.dashboard",
        component: () => import("@/hr-company/pages/HrDashboard.vue"),
      },
    ],
  },
  {
    path: "/company",
    meta: { allowed_user_types: ["hr_team"] },
    component: () => import("@/hr-company/HRCompany.vue"),
    children: [
      {
        path: "",
        name: "company",
        component: () => import("@/hr-company/pages/HrIndexView.vue"),
      },
      {
        path: ":employer_id",
        meta: { breadcrumb_name: ":name" },
        children: [
          {
            path: "",
            name: "employer.edit",
            component: () => import("@/hr-company/pages/CompanyEditView.vue"),
          },
          {
            path: "jobs",
            meta: { allowed_user_types: ["hr_team"], breadcrumb_name: "Jobs" },
            component: () => import("@/hr-employerjobs/pages/HrJobs.vue"),
            children: [
              {
                path: "",
                name: "employer.jobs",
                component: () =>
                  import("@/hr-employerjobs/pages/EmployerJobs.vue"),
              },
              {
                path: ":job_hashid",
                meta: { breadcrumb_name: ":job_title" },
                children: [
                  {
                    path: "",
                    name: "jobs.hr",
                    component: () =>
                      import("@/hr-employerjobs/pages/HRJobsEditView.vue"),
                  },
                  {
                    path: "candidates",
                    name: "candidates.manage",
                    meta: {
                      allowed_user_types: ["hr_team"],
                      breadcrumb_name: "Manage Candidates",
                    },
                    component: () =>
                      import(
                        "@/hr-employerjobs/components/HRCandidatesIndexPage.vue"
                      ),
                  },
                  {
                    path: "candidates/:candidate_id",
                    meta: { allowed_user_types: ["hr_team"] },
                    children: [
                      {
                        path: "",
                        name: "candidates.view",
                        component: () =>
                          import(
                            "@/hr-employerjobs/pages/HRCandidateProfilePage.vue"
                          ),
                      },
                      {
                        path: "schedule",
                        name: "hr.candidate.interview.schedule",
                        component: () =>
                          import("@/scheduler/pages/CommonScheduler.vue"),
                      },
                      {
                        path: "schedule",
                        name: "hr.candidate.interview.schedule",
                        component: () =>
                          import("@/scheduler/pages/CommonScheduler.vue"),
                      },
                      {
                        path: "interview",
                        name: "hr.candidate.interview.show",
                        component: () =>
                          import("@/candidate/pages/CandidateInterview.vue"),
                        meta: {},
                      },
                    ],
                  },
                ],
              },
              {
                path: "new",
                component: () => import("@/hr-employerjobs/pages/HrJobs.vue"),
                children: [
                  {
                    path: "",
                    name: "employer-jobs.create",
                    component: () => import("@/jobs/pages/JobAIGenerate.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/all_jobs",
    meta: { allowed_user_types: ["hr_team"] },
    name: "hr_jobs.index",
    component: () => import("@/hr-employerjobs/pages/HRJobsIndexView.vue"),
    props: (route) => ({ job_hashid: route.query.job_hashid }),  
  },
  {
    path: "/job/create",
    meta: { allowed_user_types: ["hr_team"] },
    component: () => import("@/hr-employerjobs/pages/HrJobs.vue"),
    children: [
      {
        path: "",
        name: "hr_jobs.create",
        component: () => import("@/hr-employerjobs/pages/HRJobAIGenerate.vue"),
      },
    ],
  },
];

export default HRRoutes;
