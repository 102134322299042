import { mapActions, mapGetters, mapMutations } from "vuex";

export const JOBS_MODULE_NAME = "JobsModule";

const initState = {
  is_connected: false,
  jobs: null,
  filter_options: null,
  selected_job: null,
  selected_job_hashid: null,
  selected_candidate: null,
  selected_employer: null,
  job_candidates: [],
  isLoading: false,
  manage_data: null,
};

const JobsModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    jobs: (s) => s.jobs,
    filter_options: (s) => s.filter_options,
    selected_job: (s) => s.selected_job,
    selected_job_hashid: (s) => s.selected_job_hashid,
    selected_candidate: (s) => s.selected_candidate,
    selected_employer: (s) => s.selected_employer,
    job_candidates: (s) => s.job_candidates,
    isLoading: (s) => s.isLoading,
    jobByID: (s) => (id_to_match) => {
      if (s.jobs.length === 0) return null;
      if (!id_to_match) return s.jobs[0];
      return s.jobs.find((job) => job.job_hashid === id_to_match);
    },
    manage_data: (s) => s.manage_data,
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    job_filter_options(state, { body: filter_options, params }) {
      state.filter_options = filter_options;
    },
    //List All Jobs
    index(state, { body: jobs, params }) {
      state.jobs = jobs;
    },
    index_candidate_jobs(state, { body: candidate_jobs, params }) {
      state.jobs = candidate_jobs;
    },
    index_job_candidates(state, { body: job_candidates, params }) {
      state.job_candidates = job_candidates;
    },
    index_jobs_for_hr_team_member(state, { body: jobs, params }){
      state.jobs = jobs;
    },
    new_applicant_appear_to_job(state, { body: body, params }){
      const index = state.jobs.findIndex((b) => b.job_hashid === body.job_hashid);
      if (index > -1) {
        state.jobs[index].unseen_candidates += 1;
      }
    },
    //Create Jobs
    create(state, { body: job, params }) {
      if(state.jobs) {
        state.jobs = [...state.jobs, job];
        console.log(job);
        state.selected_job = job || null;
        console.log(state.selected_job);
        //Sorting
        state.jobs.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
      } 
    },
    //Single Job
    edit(state, { body: job, params }) {
      state.selected_job = job || null;
    },
    //Delete Job
    destroy(state, { body: job, params }) {
      const index = state.jobs.findIndex((b) => b.job_hashid === job.job_hashid);
      if (index > -1) {
        state.jobs.splice(index, 1);
      }

      state.selected_job = null;
    },
    //Update Job
    update(state, { body: job, params }) {
      const index = state.jobs.findIndex((b) => b.job_hashid === job.job_hashid);
      console.log("index", index);
      if (index > -1) {
        const updatedJob = { ...state.jobs[index], ...job}; // update changes only
        state.jobs.splice(index, 1, updatedJob);
        state.selected_job = updatedJob;
      }
      else{
        state.selected_job=null
      }
    },
    get_candidate(state, { body: selected_candidate, params }) {
      state.selected_candidate = selected_candidate;
      state.isLoading = false;
    },
    fetch_employer(state, { body:selected_employer, params}){
      state.selected_employer = selected_employer;
    },
    apply_for_job(state, { body: job, params }) {
      const job_idx = state.jobs.findIndex((e) => e.job_hashid === job.job_hashid);
      state.jobs.splice(job_idx, 1, job);
    },
    unapply_for_job(state, { body: job, params }) {
      const job_idx = state.jobs.findIndex((e) => e.job_hashid === job.job_hashid);
      state.jobs.splice(job_idx, 1, job);
    },
    update_candidate_status(state, { body: candidate, params }) {
      state.selected_candidate = candidate;
    },
    auto_scheduling_for_job(state, { body, params }) {
      console.log("Jobs Scheduled");
    },
    // manage access
    get_current_employer_job_permissions(state, {body: manage_data, params}){
      state.manage_data = manage_data;
    },
    curd_access_employer_job_to_hr_team_member(state, {body: body, params}){
      let index = -1;
      switch (params.curd_action) {
        case "create":
          state.manage_data.assigned_team_members.push(body);
          state.manage_data.remaining_team_members = state.manage_data.remaining_team_members.filter(
            (member) => member.hr_team_member_details.hr_team_id !== body.hr_team_member_details.hr_team_id
          );
          break;
        case "update":
          index = state.manage_data.assigned_team_members.findIndex(
            (b) => b.hr_team_member_details.hr_team_id === body.hr_team_member_details.hr_team_id
          );
          if (index > -1) {
            state.manage_data.assigned_team_members.splice(index, 1, body);
          }
          break;
        case "destroy":
          index = state.manage_data.assigned_team_members.findIndex(
            (b) => b.hr_team_member_details.hr_team_id === params.hr_team_id
          );
          if (index > -1) {
            // append data to required access list 
            state.manage_data.remaining_team_members.push({hr_team_member_details: state.manage_data.assigned_team_members[index].hr_team_member_details});
            // remove from already have access list
            state.manage_data.assigned_team_members.splice(index, 1);
          }
          break;
      }
    },
  },
  actions: {
    set_selected_job_hashid({ state }, job_hashid) {
      state.selected_job_hashid = job_hashid;
    },
    set_selected_job({ state, getters }, job_hashid) {
      console.log("Setting Job");
      state.selected_job = { ...getters.jobByID(job_hashid) };
      console.log(getters.selected_job);
    },
    set_loading({ state }, _flag = true) {
      state.isLoading = _flag;
    }
  }
};

export const mapJobsActions = (data) => mapActions(JOBS_MODULE_NAME, data);
export const mapJobsGetters = (data) => mapGetters(JOBS_MODULE_NAME, data);

export const mapJobsMutations = (data) => mapMutations(JOBS_MODULE_NAME, data);

export default JobsModule;
