import { mapActions, mapGetters, mapMutations } from "vuex";
import store from "@/global-store.index";

export const PROFILE_EDIT_MODULE_NAME = "ProfileEditModule";

const capitalize_text = (text) => {
  if (!text) return null;

  if (text.length < 4) return text.toUpperCase();

  return text.replace(/\b\w/g, function (m) {
    return m.toUpperCase();
  });
};
const transformRoleName = (name, mode = "to-name", capitalize = true) => {
  if (!name) return "";
  if (!mode || mode === "to-name") {
    const transformed_text = name
      .trim()
      .replaceAll("_", " ")
      .replaceAll("-", " ");
    return capitalize ? capitalize_text(transformed_text) : transformed_text;
  } else if (mode === "to-key")
    return name.trim().toLowerCase().replaceAll(" ", "_").replaceAll("-", "_");
};

const initState = {
  is_connected: false,
  isLoading: true,
  current_profile: null,
  hr_company: null,
  modal_fields: [],
  modal_data: null,
  show_modal: false,
  selected_form_id: null,
  selected_form_name: null,
  selected_form_meta: null,
  applied_jobs: null,
  team_members: null,
  current_user_employer: null,
  hr_team_member: null,
  upcoming_meetings: null,
  role_permissions_info: null,
  candidate_profile_request_ai_status: "not_yet_requested",
  progressBarWidth: "0%",
};

const ProfileEditModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    isLoading: (s) => s.isLoading,
    current_profile: (s) => s.current_profile,
    hr_company: (s) => s.hr_company,
    modal_fields: (s) => s.modal_fields,
    modal_data: (s) => s.modal_data,
    show_modal: (s) => s.show_modal,
    selected_form_id: (s) => s.selected_form_id,
    selected_form_name: (s) => s.selected_form_name,
    selected_form_meta: (s) => s.selected_form_meta,
    applied_jobs: (s) => s.applied_jobs,
    team_members: (s) => s.team_members,
    current_user_employer: (s) => s.current_user_employer,
    hr_team_member: (s) => s.hr_team_member,
    role_permissions_info: (s) => s.role_permissions_info,
    upcoming_meetings: (s) => {
      if (s.upcoming_meetings)
        return s.upcoming_meetings.filter(
          (eachMeeting) => new Date(eachMeeting.time.event_start) >= new Date()
        );
      else return s.upcoming_meetings;
    },
    designation_options: (s) => {
      if (!s.role_permissions_info) return null;

      return s.role_permissions_info.map((each_role) => {
        return {
          value: each_role.role_name,
          text: transformRoleName(each_role.role_name),
          enable_condition: [
            {
              dependent_on_object: "permissions",
              dependency_paths: [
                `user_employer_curd.${each_role.role_name}.create`,
              ],
            },
          ],
        };
      });
    },
    hr_designation_options: (s) => {
      if (!s.role_permissions_info) return null;

      return s.role_permissions_info.map((each_role) => {
        return {
          value: each_role.id,
          text: transformRoleName(each_role.role_name),
          enable_condition: [
            {
              dependent_on_object: "permissions",
              dependency_paths: [
                `team_member_curd.${each_role.id}.create`,
              ],
            },
          ],
        };
      });
    },
    candidate_profile_request_ai_status: (s) =>
      s.candidate_profile_request_ai_status,
    progressBarWidth: (s) => s.progressBarWidth,
  },
  mutations: {
    /*Common To Candidate and Employer*/
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    loading(state, flag) {
      state.isLoading = flag;
    },
    update(state, { body: current_profile, params }) {
      state.current_profile = current_profile;
    },
    edit(state, { body: current_profile, params }) {
      state.current_profile = current_profile;
    },
    set_selected_form_data(state, { form_id, form_meta }) {
      state.selected_form_id = form_id;
      state.selected_form_meta = form_meta;
      state.selected_form_name = form_id?.split(".")[0]?.split("_")?.join(" ");
    },
    set_modal_data(state, modal_data) {
      state.modal_data = modal_data;
    },
    set_modal_fields(state, modal_fields) {
      state.modal_fields = modal_fields;
    },
    set_modal_state(state, intent) {
      console.log("Set Modal");
      state.show_modal = intent;
    },
    upcoming_meeting(state, { body: upcoming_meetings, params }) {
      state.upcoming_meetings = upcoming_meetings;
    },
    /*Common END*/
    /*Candidate Specific*/
    index_job(state, { body: applied_jobs, params }) {
      state.applied_jobs = applied_jobs;
    },
    complete_profile_by_resume(state, { body: response, params }) {
      state.candidate_profile_request_ai_status = response.response_status;
      if (response.response_status === "InProgress") {
        let progress = response.progress_percentage;
        state.progressBarWidth = `${progress}%`;
      }
    },
    /*Candidate Specific End*/
    /*Employer Specific*/
    update_company(state, { body: current_profile, params }) {
      state.current_profile = current_profile;
    },
    edit_company(state, { body: current_profile, params }) {
      state.current_profile = current_profile;
    },
    create_user_employer(state, { body: member, params }) {
      state.team_members = [member, ...state.team_members];
    },
    index_user_employer(state, { body: team_members, params }) {
      state.team_members = team_members;
    },
    fetch_current_user_employer(
      state,
      { body: current_user_employer, params }
    ) {
      state.current_user_employer = current_user_employer;
    },
    update_current_user_employer(
      state,
      { body: current_user_employer, params }
    ) {
      state.current_user_employer = current_user_employer;
    },
    destroy_user_employer(state, { body: member, params }) {
      const deleted_member_idx = state.team_members.findIndex(
        (each_member) =>
          each_member.user_employer_id === params.user_employer_id
      );

      state.team_members.splice(deleted_member_idx, 1);

      state.team_members = [...state.team_members];
    },
    update_user_employer(state, { body: member, params }) {
      const updated_member_idx = state.team_members.findIndex(
        (each_member) =>
          each_member.user_employer_id === member.user_employer_id
      );

      state.team_members[updated_member_idx] = member;
    },
    fetch_role_permissions_info(
      state,
      { body: role_permissions_info, params }
    ) {
      state.role_permissions_info = role_permissions_info;
    },
    async update_role(state, { body: updated_role, params }) {
      /* const update_role_idx = state.role_permissions_info.findIndex((each_role) => each_role.employer_role_id === updated_role.id);

      state.role_permissions_info.splice(update_role_idx, 1, updated_role);*/

      await store.dispatch("LoginStore/fetchDetails");
    },
    async create_role(state, { body: new_role, params }) {
      //state.role_permissions_info.push(new_role);
      await store.dispatch("LoginStore/fetchDetails");
    },
    async destroy_role(state, { body: deleted_role, params }) {
      /*  const deleted_role_idx = state.role_permissions_info.findIndex((each_role) => each_role.employer_role_id === params.employer_role_id);

      state.role_permissions_info.splice(deleted_role_idx, 1);*/
      await store.dispatch("LoginStore/fetchDetails");
    },
    /*Employer Specific END*/

    /* Hr Specific */
    update_hr_company(state, { body: hr_company, params }) {
      state.hr_company = hr_company;
    },
    edit_hr_company(state, { body: hr_company, params }) {
      state.hr_company = hr_company;
    },
    create_hr_team(state, { body: member, params }) {
      state.team_members = [...state.team_members, member];
    },
    index_hr_team(state, { body: team_members, params }) {
      state.team_members = team_members;
    },
    update_hr_team(state, { body: team_member, params }) {
      const team_member_idx = state.team_members.findIndex(
        (each_member) => each_member.hr_team_id === params.hr_team_id
      );
      if(team_member_idx>-1)
        state.team_members.splice(team_member_idx, 1, team_member);
    },
    destroy_hr_team(state, { body: team_member, params }) {
      const deleted_member_idx = state.team_members.findIndex(
        (each_member) => each_member.hr_team_id === params.hr_team_id
      );

      state.team_members.splice(deleted_member_idx, 1);
      state.team_members = [...state.team_members];
    },
    fetch_current_hr_team_member(state, { body: hr_team_member, params }) {
      state.hr_team_member = hr_team_member;
    },
    update_current_hr_team_member(state, { body: hr_team_member, params }) {
      state.hr_team_member = hr_team_member;
    },
    fetch_emp_roles(state, { body: role_permissions_info, params }) {
      state.role_permissions_info = role_permissions_info;
    },
    /* hr specific ends */
  },
  actions: {},
};

export const mapProfileEditActions = (data) =>
  mapActions(PROFILE_EDIT_MODULE_NAME, data);
export const mapProfileEditGetters = (data) =>
  mapGetters(PROFILE_EDIT_MODULE_NAME, data);

export const mapProfileEditMutations = (data) =>
  mapMutations(PROFILE_EDIT_MODULE_NAME, data);

export default ProfileEditModule;
